import { Link } from "react-router-dom";
import {
  UserOutlined,
  HomeOutlined,
  LoginOutlined,
  GiftOutlined,
  BookOutlined,
  EuroOutlined,
  UsergroupAddOutlined,
  TransactionOutlined,
  PlusCircleOutlined,
  CodeSandboxOutlined,
  LinkOutlined,
  FallOutlined,
  BarcodeOutlined,
  CustomerServiceFilled,
  CustomerServiceOutlined,
  QuestionCircleFilled,
  EditOutlined,
  TagOutlined,
  TagsOutlined,
  FundOutlined,
  SnippetsOutlined,
  NotificationOutlined,
} from "@ant-design/icons";

const pages = [
  {
    key: 1,
    icon: <LoginOutlined />,
    label: <Link to={"/login"}>LOGIN</Link>,
  },
  // {
  //   key: 2,
  //   icon: <HomeOutlined />,
  //   label: <Link to={"/"}>HOME</Link>,
  //   roleid: [1, 2, 3, 4, 5],
  //   rolename: ["crm", "developer", "admin", "superAdmin"],
  //   rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  // },

  // {
  //   key: 10,
  //   icon: <PlusCircleOutlined />,
  //   label: <Link to={"/create-coupon"}>CREATE COUPON</Link>,
  //   roleid: [1, 2, 3, 4, 5],
  //   rolename: ["crm", "developer", "admin", "superAdmin"],
  //   rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  // },
  // {
  //   key: 11,
  //   icon: <CodeSandboxOutlined />,
  //   label: <Link to={"/create-package"}>CREATE PACKAGE</Link>,
  //   roleid: [3, 4, 5],
  //   rolename: ["developer", "admin", "superAdmin"],
  //   rolecode: ["msvwerv02", "po6coorox", "pt04n1dT"],
  // },
  // {
  //   key: 12,
  //   icon: <LinkOutlined />,
  //   label: <Link to={"/product-links"}>PRODUCT LINKS</Link>,
  //   roleid: [2],
  //   rolename: ["crm", "developer", "admin", "superAdmin"],
  //   rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  // },

  // {
  //   key: 14,
  //   icon: <CustomerServiceFilled />,
  //   label: <Link to={"/create-provider"}>CREATE PROVIDERS</Link>,
  //   roleid: [2, 3, 4, 5],
  //   rolename: ["crm", "developer", "admin", "superAdmin"],
  //   rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  // },

  // {
  //   key: 16,
  //   icon: <EditOutlined />,
  //   label: <Link to={"/create-question"}>CREATE QUESTION</Link>,
  //   roleid: [2, 3, 4, 5],
  //   rolename: ["crm", "developer", "admin", "superAdmin"],
  //   rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  // },

  // {
  //   key: 19,
  //   icon: <TagOutlined />,
  //   label: <Link to={"/create-exclusive-product"}>CREATE PRODUCT</Link>,
  //   roleid: [3, 4, 5],
  //   rolename: ["developer", "admin", "superAdmin"],
  //   rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  // },

  {
    key: 412,
    icon: <PlusCircleOutlined />,
    label: "CUSTOMER",
    roleid: [5],
    rolename: ["crm", "developer", "admin", "superAdmin"],
    rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
    children: [
      {
        key: 18,
        icon: <GiftOutlined />,
        label: <Link to={"/gift-members"}>GIFT MEMBERS</Link>,
        roleid: [3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },

      {
        key: 3,
        icon: <UserOutlined />,
        label: <Link to={"/users"}>USERS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },

      {
        key: 6,
        icon: <BookOutlined />,
        label: <Link to={"/user-courses"}>USER COURSES</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 6123,
        icon: <BookOutlined />,
        label: <Link to={"/supplier-orders"}>SUPPLIER ORDERS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 6124,
        icon: <BookOutlined />,
        label: <Link to={"/buybox"}>BUYBOX</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
    ],
  },
  {
    key: 413,
    icon: <PlusCircleOutlined />,
    label: "MEMBERS",
    roleid: [5],
    rolename: ["crm", "developer", "admin", "superAdmin"],
    rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
    children: [
      {
        key: 20,
        icon: <TagsOutlined />,
        label: <Link to={"/exclusive-products"}>EXCLUSIVE PRODUCTS</Link>,
        roleid: [3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 17,
        icon: <QuestionCircleFilled />,
        label: <Link to={"/questions"}>QUESTIONS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 5,
        icon: <BookOutlined />,
        label: <Link to={"/courses"}>COURSES</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 15,
        icon: <CustomerServiceOutlined />,
        label: <Link to={"/service-providers"}>SERVICE PROVIDERS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 16,
        icon: <FundOutlined />,
        label: <Link to={"/amazon-bsr"}>AMAZON BSR</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 33,
        icon: <FundOutlined />,
        label: <Link to={"/ali-express"}>ALIEXPRESS EXCEL</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 212,
        icon: <UsergroupAddOutlined />,
        label: <Link to={"/producers"}>MAĞAZALAR</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 1212,
        icon: <UsergroupAddOutlined />,
        label: <Link to={"/create-store"}>MAĞAZA OLUŞTUR</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 21,
        icon: <SnippetsOutlined />,
        label: <Link to={"/blog-posts"}>BLOG</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },

      {
        key: 213,
        icon: <SnippetsOutlined />,
        label: <Link to={"/ai-cats"}>AI Cats</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },

      {
        key: 2132,
        icon: <SnippetsOutlined />,
        label: <Link to={"/services"}>SERVICES</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },

      {
        key: 21322,
        icon: <UsergroupAddOutlined />,
        label: <Link to={"/contact-form-answers"}>CONTACT FORM ANSWERS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },

      {
        key: 21323,
        icon: <NotificationOutlined />,
        label: <Link to={"/announcements"}>ANNOUNCEMENTS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 213255,
        icon: < CodeSandboxOutlined/>,
        label: <Link to={"/marketplace-products"}>MARKETPLACE PRODUCTS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
    ],
  },
  {
    key: 406,
    icon: <PlusCircleOutlined />,
    label: "FINANCE",
    roleid: [5],
    rolename: ["crm", "developer", "admin", "superAdmin"],
    rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
    children: [
      {
        key: 13,
        icon: <BarcodeOutlined />,
        label: <Link to={"/packages"}>PACKAGES</Link>,
        roleid: [2],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 7,
        icon: <EuroOutlined />,
        label: <Link to={"/invoices"}>INVOICES</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 8,
        icon: <SnippetsOutlined />,
        label: <Link to={"/birfatura"}>BIRFATURA</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 9,
        icon: <UsergroupAddOutlined />,
        label: <Link to={"/subscriptions"}>SUBSCRIPTIONS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 10,
        icon: <TransactionOutlined />,
        label: <Link to={"/transactions"}>TRANSACTIONS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 4,
        icon: <GiftOutlined />,
        label: <Link to={"/coupons"}>COUPONS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 123,
        icon: <UsergroupAddOutlined />,
        label: <Link to={"/service-providers-sub"}>PROVIDER SUBS</Link>,
        roleid: [2, 3, 4, 5],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 57,
        icon: <BarcodeOutlined />,
        label: <Link to={"/plans"}>PLANS</Link>,
        roleid: [2],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 58,
        icon: <CustomerServiceOutlined />,
        label: <Link to={"/unpaids"}>UNPAIDS</Link>,
        roleid: [2],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
      {
        key: 666,
        icon: <TagOutlined />,
        label: <Link to={"/wallet-transactions"}>WALLET TRANSACTIONS</Link>,
        roleid: [2],
        rolename: ["crm", "developer", "admin", "superAdmin"],
        rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
      },
    ],
  },
  {
    key: 1405,
    icon: <PlusCircleOutlined />,
    label: "DELOITTE",
    roleid: [3,4,5],
    rolename: [ "developer", "admin", "superAdmin"],
    rolecode: ["msvwerv02", "po6coorox", "pt04n1dT"],
    children: [{
      key: 121312,
      icon: <FundOutlined />,
      label: <Link to={"/deloitte-amazon-bsr"}>DELOITTE AMAZON BSR</Link>,
      roleid: [2],
      rolename: ["crm", "developer", "admin", "superAdmin"],
      rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
    }]
  },
  {
    key: 404,
    icon: <FallOutlined />,
    label: <Link to={"/error-logs"}>ERROR LOGS</Link>,
    roleid: [5],
    rolename: ["crm", "developer", "admin", "superAdmin"],
    rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  },
  {
    key: 1404,
    icon: <FallOutlined />,
    label: <Link to={"/test-ai"}>Test AI Doc</Link>,
    roleid: [5],
    rolename: ["crm", "developer", "admin", "superAdmin"],
    rolecode: ["mıbB043", "msvwerv02", "po6coorox", "pt04n1dT"],
  },
];

export default pages;
