import React, { useEffect, useState } from "react";
import { Form, InputNumber, Button, Select, Space } from "antd";
import AdminService from "../../services/adminService"; // AdminService import edin

const { Option } = Select;

interface RuleDataFormProps {
  field: any;
  ruleType: string;
  remove: (name: number | number[]) => void;
}

const RuleDataForm: React.FC<RuleDataFormProps> = ({ field, ruleType, remove }) => {
  const [suppliers, setSuppliers] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    // AdminService'den supplierları getirme işlemi
    const fetchSuppliers = async () => {
      try {
        const response = await AdminService.getSuppliers(); // Supplierları getiren fonksiyon
        
        setSuppliers(response.data); // Gelen veriyi state'e set ediyoruz
      } catch (error) {
        console.error("Failed to fetch suppliers", error);
      }
    };

    fetchSuppliers();
  }, []);

  return (
    <Space
      key={field.key}
      style={{ display: "flex", marginBottom: 8 }}
      align="baseline"
    >
      {ruleType === "location" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "supplierId gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "location"]}
            fieldKey={[field.fieldKey, "location"]}
            rules={[{ required: true, message: "Location gerekli!" }]}
          >
            <Select placeholder="Location">
              <Option value="EU">EU</Option>
              <Option value="AMERIKA">AMERIKA</Option>
            </Select>
          </Form.Item>
        </>
      )}

      {ruleType === "cycle" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "cycle"]}
            fieldKey={[field.fieldKey, "cycle"]}
            rules={[{ required: true, message: "Cycle gerekli!" }]}
          >
            <InputNumber placeholder="Cycle" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "Supplier ID gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "cycleCounter"]}
            fieldKey={[field.fieldKey, "cycleCounter"]}
            rules={[{ required: true, message: "Cycle Counter gerekli!" }]}
          >
            <InputNumber placeholder="Cycle Counter" />
          </Form.Item>
        </>
      )}

      {ruleType === "direct" && (
        <>
          <Form.Item
            {...field}
            name={[field.name, "price"]}
            fieldKey={[field.fieldKey, "price"]}
            rules={[{ required: true, message: "Price gerekli!" }]}
          >
            <InputNumber placeholder="Price" />
          </Form.Item>
          <Form.Item
            {...field}
            name={[field.name, "supplierId"]}
            fieldKey={[field.fieldKey, "supplierId"]}
            rules={[{ required: true, message: "Supplier ID gerekli!" }]}
          >
            <Select placeholder="Select Supplier">
              {suppliers.map((supplier) => (
                <Option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      <Button type="link" onClick={() => remove(field.name)}>
        Sil
      </Button>
    </Space>
  );
};

export default RuleDataForm;