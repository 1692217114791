import React, { useState, useEffect } from "react";

import AdminServices from "../services/adminService";
import alertNotification from "../utils/alertNotification";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import { Tag, Row, Col,Select } from "antd";


import Filter from "../components/filter/Filter";
import UserTable from "../components/tables/pageTables/UserTable";

import { User as DataType } from "../interfaces/adminInterfaces";
import SupplierOrdersTable from "../components/tables/pageTables/SupplierOrdersTable";



const SupplierOrders: React.FC = () => {

  const { Option } = Select;
  const months = [
    { value: 0, label: "Tümü",year:0 },
    { value: 10, label: "Ekim 2024",year:2024 },
    { value: 11, label: "Kasım 2024",year:2024  },
    { value: 12, label: "Aralık 2024" ,year:2025 },
    { value: 1, label: "Ocak 2025", year:2025 },
    { value: 2, label: "Şubat 2025", year:2025 },
  ];

  const [current, setCurrent] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [users, setUsers] = useState<DataType[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('')

  const [supplierId, setSupplierId] = useState<number>(0);
  const [summaries, setSummaries] = useState<any[]>([]);
  const [walletInfos, setWalletInfos] = useState<any[]>([]);
  const [sellerShopId, setSellerShopId] = useState<number>(0);
  const [sellerShopIds,setSellerShopIds]=useState<any[]>([
    {id:0,name:"Seller Shop"}
  ])
  const [infoCards,setInfoCards]=useState<any[]>([])
  
  const [month,setMonth]=useState<number>(10)
  const [year,setYear]=useState<number>(2024)
  const [graphData, setGraphData] = useState<any[]>([
    { order_day: '2024-08-29', number: 2.7 },
  { order_day: '2024-08-30', number: 2.7 },
  { order_day: '2024-08-31', number: 0.0 },
  { order_day: '2024-09-01', number: 6.9 },
  { order_day: '2024-09-02', number: 6.2 },
  { order_day: '2024-09-03', number: 0.0 },
  { order_day: '2024-09-04', number: 2.1 },
  { order_day: '2024-09-05', number: 4.0 },
  { order_day: '2024-09-06', number: 1.6 },
  { order_day: '2024-09-07', number: 4.8 },
  { order_day: '2024-09-08', number: 2.7 },
  { order_day: '2024-09-09', number: 5.6 },
  { order_day: '2024-09-10', number: 10.0 },
  { order_day: '2024-09-11', number: 7.3 },
  { order_day: '2024-09-12', number: 12.1 },
  { order_day: '2024-09-13', number: 8.6 },
  { order_day: '2024-09-14', number: 0.0 },
  { order_day: '2024-09-15', number: 9.2 },
  { order_day: '2024-09-16', number: 4.2 },
  { order_day: '2024-09-17', number: -8.1 },
  { order_day: '2024-09-18', number: 21.6 },
  { order_day: '2024-09-19', number: 33.7 },
  { order_day: '2024-09-20', number: 24.3 },
  { order_day: '2024-09-21', number: 36.1 },
  { order_day: '2024-09-22', number: 32.2 },
  { order_day: '2024-09-23', number: 22.1 },
  { order_day: '2024-09-24', number: 36.6 },
  { order_day: '2024-09-25', number: 41.5 },
  { order_day: '2024-09-26', number: 37.0 },
  { order_day: '2024-09-27', number: 74.0 },
  { order_day: '2024-09-28', number: 52.3 }
  ]);


  //fetch all users
  const getSupplierSaleSummary = (supplierId:number,month:number,year:number,sellerShopId:number) => {
    setLoading(true);
    AdminServices.getSupplierSalesSummary(supplierId,month,year,sellerShopId)
      .then((res) => {
        setSummaries(res.data);

        let totalProfit=76
        let totalProductCost=0
        let totalCargoCost=0
        let totalRexvenRevenue=0
        let totalSellerRevenue=0
        let totalDesignerRevenue=0
        let totalOrderItem=0
        let totalSupplierOrder=0

        res.data.map((item:any)=>{
          totalProfit+=Number(item.profit_sum)
          totalProductCost+=Number(item.total_sum)
          totalCargoCost+=Number(item.shipment_cost_sum)
          totalRexvenRevenue+=Number(item.commission_price_sum)
          totalSellerRevenue+=Number(item.seller_order_total)
          totalDesignerRevenue+=Number(item.design_price_sum)
          totalOrderItem+=Number(item.order_item_count_sum)
          totalSupplierOrder+=Number(item.supplier_order_count)
        })
     
        let totalProfitPerProduct=totalProfit/totalOrderItem

        setInfoCards([
          {name:"Total Profit",value:totalProfit.toFixed(0)},
          {name:"Total Product Cost",value:totalProductCost.toFixed(0)},
          {name:"Total Cargo Cost",value:totalCargoCost.toFixed(0)},
          {name:"Total Revenue",value:totalRexvenRevenue.toFixed(0)},
          {name:"Total Seller Revenue",value:totalSellerRevenue.toFixed(0)},
          {name:"Profit Per Product",value:totalProfitPerProduct.toFixed(2)},
          {name:"Total Order Item",value:totalOrderItem},
          {name:"Total Supplier Order",value:totalSupplierOrder}
        ])

        setTotalPageCount(1);
        let graphDataDb=res.data.map((item:any)=>({
          order_day:item.order_day,
          number:item.profit_sum
        }))
        graphDataDb=graphDataDb.filter((item:any)=>new Date(item.order_day)>=new Date("2024-09-28"))
        // concat graphDataDb with graphData
        graphDataDb.reverse()
        console.log(graphDataDb,"graph")
        let allData=[...graphData,...graphDataDb]
        // sort with order_day
        allData.sort((a, b) => new Date(a.order_day).getTime() - new Date(b.order_day).getTime());
        setGraphData([...graphData,...graphDataDb])
        
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
      AdminServices.getSuppliersPaymentInfos()
        .then((res) => {
          setWalletInfos(res.data);
          console.log(res.data)
        })
        .catch((err) => {
          // console.log(error);
          setError(true);
          alertNotification("error", err.response.data.message);
        })

      AdminServices.getSellerShops()
      .then(res=>{
        console.log(res.data)
        setSellerShopIds(res.data)
      })
      .catch((err) => {
        // console.log(error);
        setError(true);
        alertNotification("error", err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const handleMonthChange = (value: number) => {
    setMonth(value);
    let selectedYear=months.filter(i=>i.value===value)[0].year
    setYear(selectedYear)
    getSupplierSaleSummary(supplierId,value,selectedYear,sellerShopId)
  };



  const handleSellerShopIdChange = (value: number) => {
    setSellerShopId(value);
    getSupplierSaleSummary(supplierId,month,year,value);
  };

  const handleSupplierIdChange = (value: number) => {
    setSupplierId(value)
    getSupplierSaleSummary(value,month,year,sellerShopId);
  };

  useEffect(() => {
    getSupplierSaleSummary(supplierId,month,year,sellerShopId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>

      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        {walletInfos.map((walletInfo, index) => (
          <Col key={index}>
            <Tag color="blue">
              <strong>{walletInfo.name} : </strong>
              <Tag color="green">{Number(walletInfo.balance).toFixed(2)} USD</Tag>
            </Tag>
           
          </Col>
        ))}
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
  {infoCards.map((info, index) => (
    <Col key={index} xs={24} sm={12} md={8} lg={6}>
      <div style={{
        backgroundColor: "#f0f2f5",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        transition: "all 0.3s ease",
        // hover: { transform: "scale(1.05)" }
      }}>
        <h3 style={{ color: "#001529", marginBottom: "10px" }}>{info.name}</h3>
        <p style={{
          fontSize: "24px",
          fontWeight: "bold",
          color: "#1890ff"
        }}>{(info.name!=="Total Order Item"&&info.name!=="Total Supplier Order"&&info.name!=="Profit Per Product")? Number(info.value)+ " USD":info.value}</p>
      </div>
    </Col>
  ))}
</Row>

       {/* Filters */}
       <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
        <Col>
          <Select
            placeholder="Select Month"
            style={{ width: 120 }}
            onChange={handleMonthChange}
            value={month}
          >
            {months.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
      
        <Col>
          <Select
            placeholder="Select Seller Shop"
            style={{ width: 180 }}
            onChange={handleSellerShopIdChange}
            value={sellerShopId}
          >
            {sellerShopIds.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col>
          <Select
            placeholder="Select Supplier Shop"
            style={{ width: 180 }}
            onChange={handleSupplierIdChange}
            value={supplierId}
          >
            {walletInfos.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

   {/* Grafik */}
   {/* <div style={{ width: "100%", height: 300 }}>
        <ResponsiveContainer>
          <LineChart data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="order_day" />
            <YAxis   domain={[0, 300]} allowDataOverflow={false}/>
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="number" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div> */}


      <SupplierOrdersTable
        datas={summaries}
        loading={loading}
        setCurrent={setCurrent}
        current={current}
        totalPageCount={totalPageCount}
      />
    </>
  );
};

export default SupplierOrders;
